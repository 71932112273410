import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Toaster } from 'react-hot-toast';
import { GlobalStateProvider } from './utils/GlobalStateContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Menu } from './components/menu';
import { Home } from './screens/home_screen';
import { TicketScreen } from './screens/ticket_screen';
import { TicketListScreen } from './screens/ticket_list_screen';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalStateProvider>
    <BrowserRouter>
      <div className='_page_outer'>
        <Menu />
        <Routes>
          <Route path="/" element={<TicketListScreen />} />
          <Route path="/tickets" element={<TicketListScreen />} />
          <Route path="/ticket/:ticketId" element={<TicketScreen />} />
        </Routes>
        {/* <Chat /> */}
        <Toaster toastOptions={{
          className: '',
          style: {
            background: "#303030",
            color: '#ffffff'
          },
        }} />
      </div>
    </BrowserRouter>
    <Toaster />
  </GlobalStateProvider>
);