import { useEffect, useState } from "react"
import { server_url } from "../utils/consts"
import { Link, useNavigate } from "react-router-dom"
import { getTimeAgo } from "../utils/time_ago"
import { Space } from "../components/space"
import { Button } from "../components/button"
import { useGlobalState } from "../utils/GlobalStateContext"

export const TicketListScreen = () => {
    const navigate = useNavigate()
    const [ticketList, setTicketList] = useState()
    const { setTargetLanguage } = useGlobalState();
    const getTicketList = () => {
        fetch(`${server_url}/ticket/list`).then(res => res.json())
            .then(
                (res) => {
                    setTicketList(res)
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    useEffect(() => {
        getTicketList();
        setTargetLanguage();
    }, [])
    return (
        ticketList ?
            <div className="_page _page_ticket_list_screen">
                <span className="count">{ticketList.ticket_list.length} Destek Talebi Var</span>
                <div className="list">
                    {
                        ticketList.ticket_list.map(e =>
                            <div className="item">
                                <span className="title">{e.ticket_text}</span>
                                <Space height={6} />
                                <span className="">{getTimeAgo(e.ticket_created_date)}</span>
                                <Space height={12} />
                                <div className="actions">
                                    <Button onClick={() => navigate(`/ticket/${e.ticket_id}`)} title={"Cevapla"} />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            :
            <span>Loading</span>
    )
}