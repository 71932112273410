import { Link } from "react-router-dom"

export const Menu = () => {
    return (
        <div className="_menu">
            <div className="list">
                <Link className="item" to="tickets">Tickets</Link>
            </div>
        </div>
    )
}