export const getTimeAgo = (unixTimestamp) => {
    const now = new Date();
    const timestamp = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds

    const timeDifference = now - timestamp;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} gün önce`;
    } else if (hours > 0) {
        return `${hours} saat önce`;
    } else if (minutes > 0) {
        return `${minutes} dakika önce`;
    } else if (seconds > 0) {
        return `${seconds} saniye önce`;
    } else {
        return 'Şimdi';
    }
}