import { useState } from "react";
import { Button } from "../components/button";
import { useGlobalState } from "./GlobalStateContext";

export const translateText = async ({ original_text, target_language }) => {
    try {
        const response = await fetch(
            `https://translation.googleapis.com/language/translate/v2?q=${encodeURIComponent(
                original_text
            )}&target=${target_language ?? "tr"}&key=AIzaSyAgoaFfayL-kFLJRvrH0REi-T5y7qh6pMw`
        );

        const data = await response.json();
        if (data && data.data && data.data.translations && data.data.translations[0]) {
            return { translated_text: data.data.translations[0].translatedText, target_language: data.data.translations[0].detectedSourceLanguage }
        } else {
            console.error('Translation error:', data);
        }
    } catch (error) {
        console.error('Translation error:', error);
    }
};
export const Translate = ({ original_text }) => {
    const { targetLanguage, setTargetLanguage } = useGlobalState();
    const [translatedText, setTranslatedText] = useState()
    const getTranslate = async () => {
        const translate_data = await translateText({ original_text, targetLanguage });
        setTranslatedText(translate_data.translated_text);
        setTargetLanguage(translate_data.target_language);
    }
    return (
        <div className="_translate">
            {
                translatedText ?
                    <span className="translated_text">{translatedText}</span>
                    :
                    <Button title={"Çevir"} onClick={() => getTranslate()} />
            }
        </div>
    )
}