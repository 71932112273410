import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { server_url } from "../utils/consts";
import { Button } from "../components/button";
import { Space } from "../components/space";
import TimeAgo, { getTimeAgo } from "../utils/time_ago";
import toast from "react-hot-toast";
import { Translate, translateText } from "../utils/google_translate";
import { useGlobalState } from "../utils/GlobalStateContext";

export const TicketScreen = () => {
    const { ticketId } = useParams()
    const [ticketData, setTicketData] = useState();
    const [ticketText, setTicketText] = useState();
    const { targetLanguage } = useGlobalState();
    const messagesRef = useRef(null);
    const navigate = useNavigate();

    const getTicketData = () => {
        fetch(`${server_url}/ticket/data?ticket_id=${ticketId}`)
            .then(res => res.json())
            .then(
                (res) => {
                    setTicketData(res)
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const ticketReply = async ({ is_translate }) => {
        const translate_data = await translateText({ original_text: ticketText, target_language: targetLanguage });
        toast.promise(
            new Promise((resolve, reject) => {
                fetch(`${server_url}/ticket/reply`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ticket_id: ticketId,
                        ticket_text: is_translate ? translate_data.translated_text : ticketText
                    })
                }).then(res => res.json())
                    .then(
                        (res) => {
                            if (res.status)
                                resolve("Destek talebi cevaplandı!")
                            getTicketData()
                            setTicketText("")
                        },
                        (error) => {
                            reject(error)
                        }
                    )
            }),
            {
                loading: "Loading",
                success: msg => msg,
                error: msg => msg
            }
        )
    }
    const ticketDelete = () => {
        toast.promise(
            new Promise((resolve, reject) => {
                fetch(`${server_url}/ticket/delete`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ticket_id: ticketId
                    })
                }).then(res => res.json())
                    .then(
                        (res) => {
                            if (res.status) {
                                resolve("Destek talebi silindi!")
                                navigate("/tickets")
                            }
                        },
                        (error) => {
                            reject(error)
                        }
                    )
            }),
            {
                loading: "Loading",
                success: msg => msg,
                error: msg => msg
            }
        )
    }
    useEffect(() => {
        getTicketData();
    }, [])
    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [ticketData])
    return (
        ticketData ?
            <div className="_page _page_ticket_screen">
                <span className="title">Ticket #{ticketId} - {ticketData.user_data.userAppName} - User ID: {ticketData.user_data.userId} - User Phones: {ticketData.user_data.userPhones}</span>
                <Space height={24} />
                <div className="list" ref={messagesRef} >
                    {ticketData.ticket_data.map(e =>
                        <div className={`item${e.ticket_is_reply === 1 ? " sent" : ""}`}>
                            <span className="text">{e.ticket_text}</span>
                            {
                                e.ticket_is_reply === 0 &&
                                <>
                                    <Space height={4} />
                                    <Translate original_text={e.ticket_text} />
                                    <Space height={16} />
                                </>
                            }
                            <span className="created_date">{getTimeAgo(e.ticket_created_date)}</span>
                        </div>
                    )}
                </div>
                <Space height={24} />
                <textarea placeholder="Mesaj..." value={ticketText} onChange={e => setTicketText(e.target.value)} />
                <Space height={48} />
                <div className="actions">
                    {targetLanguage &&
                        <>
                            <Button title={"Ayni Dilde Cevapla"} onClick={() => { ticketReply({ is_translate: true }) }} />
                            <Space width={24} />
                        </>
                    }
                    <Button title={"Cevapla"} onClick={() => { ticketReply({ is_translate: false }) }} />
                    <Space width={24} />
                    <Button title={"Sil"} onClick={() => { ticketDelete() }} background={"red"} />
                </div>
            </div>
            :
            <span>Loading</span>
    )
}